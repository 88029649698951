<template>
    <div>
        <transition name="fade-left" mode="out-in">
            <div class="paginacao" v-if="totalPaginas">
                <!-- <div class="paginacao"> -->
                <button :disabled="pagina === 1" @click="funcAnteriorPag">
                    Anterior
                </button>
                <p>{{ pagina }} - {{ totalPaginas }}</p>
                <button :disabled="pagina === totalPaginas" @click="funcProximaPag">
                    Próxima
                </button>
            </div>
        </transition>
        <transition name="fade-left" mode="out-in">
            <div v-if="linhas.length" class="tabela-informacoes" key="Lista">
                <div class="tabela-linha tabela-linha-titulo">

                    <div v-for="coluna in colunas" :class="`tabela-linha-${verificarTamanhoClasse(coluna.tamanho)}`"
                        :key="coluna.id">
                        <p>{{ coluna.titulo }}</p>
                    </div>

                </div>

                <div class="tabela-linha tabela-linha-informacoes" v-for="ocorrencia in linhas" :key="ocorrencia.id">
                    <div v-for="key in keysColunas.length"
                        :class="`tabela-linha-${verificarTamanhoClasse(colunas[key - 1].tamanho)}`" :key="key">

                        <!-- Para datas -->
                        <p v-if="keysColunas[key - 1] === 'dataInicio' || keysColunas[key - 1] === 'dataFim'">
                            {{ ocorrencia[keysColunas[key - 1]] | formatarData }}
                        </p>

                        <!-- Para CNPJ -->
                        <p v-else-if="keysColunas[key - 1] === 'cnpj' || keysColunas[key - 1] === 'autorizadaCNPJ'">
                            {{ ocorrencia[keysColunas[key - 1]] | formataCNPJ }}
                        </p>

                        <!-- Para CPF -->
                        <p v-else-if="keysColunas[key - 1] === 'cpf'">
                            {{ ocorrencia[keysColunas[key - 1]] | formataCPF }}
                        </p>

                        <p v-else-if="keysColunas[key - 1] === 'celular'">
                            {{ ocorrencia[keysColunas[key - 1]] | formataCelular }}
                        </p>

                        <p v-else-if="keysColunas[key - 1] === 'autorizadaGrupoId'">
                            {{ retornaGrupo(ocorrencia[keysColunas[key - 1]]) }}
                        </p>


                        <!-- Tipos de interações apresentado pela função -->
                        <p
                            v-else-if="keysColunas[key - 1] === 'ultimaInteracao' || keysColunas[key - 1] === 'sacMensagemDestinatarioId'">
                            {{ verificarTipoInteracao(ocorrencia[keysColunas[key - 1]]) }}
                        </p>

                        <!-- Caso seja um anexo que abre uma nova aba escrito Visualizar -->
                        <p v-else-if="keysColunas[key - 1] === 'anexos'">
                            <a v-if="ocorrencia[keysColunas[key - 1]][0]"
                                :href="ocorrencia[keysColunas[key - 1]][0].arquivo" target="_blank"
                                rel="noopener noreferrer">
                                Visualizar
                            </a>
                            <span v-else>-</span>
                        </p>

                        <!-- Link e o título é o link também -->
                        <p v-else-if="keysColunas[key - 1] === 'link'">
                            <a :href="ocorrencia[keysColunas[key - 1]]" target="_blank" rel="noopener noreferrer">
                                {{ ocorrencia[keysColunas[key - 1]] }}
                            </a>
                        </p>

                        <!-- Imagem como link -->
                        <a v-else-if="keysColunas[key - 1] === 'imagemDesktop' || keysColunas[key - 1] === 'imagemMobile'"
                            :style="`background: url('${ocorrencia[keysColunas[key - 1]]}') center center / contain no-repeat;`"></a>

                        <!-- Acesso ao perfil usuário acesso ao espelho -->
                        <p v-else-if="keysColunas[key - 1] === 'acesso-espelho'">
                            <span v-if="ocorrencia.cargo === 'adm_fbr'">-</span>
                            <span v-else><a :href="colunas[key - 1].func(ocorrencia.id)" target="_blank">Acessar</a></span>
                        </p>

                        <!-- Acesso ao perfil usuário -->
                        <p v-else-if="keysColunas[key - 1] === 'cargo'">
                            <span v-if="ocorrencia.cargo === 'adm_fbr'">{{ ocorrencia.perfil }}</span>
                            <span v-else>{{ ocorrencia.cargo }}</span>
                        </p>

                        <!-- Caso seja um direcionamento normal vue-router-link -->
                        <p v-else-if="keysColunas[key - 1] === 'link-normal'">
                            <router-link :to="colunas[key - 1].func(ocorrencia.id)">Detalhes</router-link>
                        </p>

                        <!-- Caso link no botão -->
                        <router-link :to="colunas[key - 1].func(ocorrencia.id, ocorrencia.perfil)"
                            v-else-if="colunas[key - 1].link">
                            <div class="tabela-linha-btns-editar">
                                <img src="@/assets/images/icones/editar.svg" alt="Editar" />
                            </div>
                        </router-link>

                        <!-- Caso não for nenhum dados que precise ser tratado cairá aqui -->
                        <p v-else>{{ verificarInformacao(ocorrencia[keysColunas[key - 1]]) }}</p>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { masks } from "@/assets/js";

export default {
    name: "TabelaSac",

    props: {
        colunas: { // { key: string, titulo: string, tamanho: string, link: boolean },
            required: true,
            type: Array
        },
        linhas: {
            required: true,
            type: Array
        },
        paginacao: {
            required: true,
            type: Boolean
        },
        pagina: {
            required: false,
            type: Number
        },
        totalPaginas: {
            required: false,
            type: Number
        },
        funcProximaPag: {
            required: false,
            type: Function
        },
        funcAnteriorPag: {
            required: false,
            type: Function
        },
        grupoAutorizadas: {
            type: Array
        }
    },

    data() {
        return {
            keysColunas: [],
        }
    },

    filters: {
        formataCelular: masks.formataCelular,
        formataCPF: masks.formataCPF,
        formataCNPJ: masks.formataCNPJ,
        formatarData: masks.formatarData,
    },

    mounted() {
        this.popularDados();
    },

    methods: {
        popularDados() {
            for (const key in this.colunas) {
                this.keysColunas.push(this.colunas[key].key)
            }
        },
        detalhesOcorrencia(usuarioId, ocorrenciaId) {
            this.$router.push(`espelho/usuario/${usuarioId}/detalhes-ocorrencia/${ocorrenciaId}`)
        },
        retornaGrupo(value) {
            let filtro = this.grupoAutorizadas.find(item => item.id == value);
            return filtro.descricao;
        },
        verificarTipoInteracao(id) {
            if (id === 0 || id === '0') {
                return 'Usuário não listado'
            } else if (id === 1 || id === '1') {
                return 'Parceiro Resgate'
            } else if (id === 2 || id === '2') {
                return 'Participante'
            } else if (id === 3 || id === '3') {
                return 'Interno'
            } else if (id === 4 || id === '4') {
                return 'SAC'
            } else {
                console.log(`Error na função verificarTipoInteracao: valor recebido ${id} (tipo ${typeof id})`)
            }
        },
        verificarTamanhoClasse(tamanho) {
            switch (tamanho) {
                case "pequeno":
                    return 'id'
                case "medio-110":
                    return 'imagem'
                case "medio-130":
                    return 'cpf'
                case "medio-150":
                    return 'status'
                case "medio-240":
                    return 'valor-bonus'
                case "medio-240-visivel":
                    return 'valor-bonus col-visivel'
                case "grande":
                    return 'nome'
                case "grande-visivel":
                    return 'nome col-visivel'
                default:
                    return tamanho
            }
        },
        verificarInformacao(info) {
            return info == null || info == undefined || info == "" ? '-' : info
        }
    }
};
</script>   

<style lang="scss" scoped>
.tabela-linha-btns {
    min-width: 50px;
    max-width: 50px;

    a {
        width: 100%;
        height: 100%;
    }
}

.col-visivel {
    p {
        width: auto !important;
        white-space: normal !important;
    }
}
</style>